



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Appointment } from '@/interfaces/appoinment';
import { banks } from '@/filters/banks';

@Component({
    filters: { banks }
})
export default class Project extends Vue {
    @Prop({ required: true }) appointment!: Appointment;
}
