



































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import HandleProjectChangeMixin from '@/mixins/handle-project-change';
import { Appointment } from '@/interfaces/appoinment';
import { typeDoc } from '@/filters/type-doc';
import AppointmentHeader from './AppointmentHeader.vue';
import ThirdStepAppointment from './ThirdStepAppointment.vue';
import TimeSelect from '@/components/DialogAppointment/TimeSelect.vue';
import AppointmentDate from '@/components/DialogAppointment/AppointmentDate.vue';
import { appointmentModule } from '@/store/modules/appointment';
import { customerModule } from '@/store/modules/customer';
import FillInformationMixin from '../../mixins/fill-information';
import { Customer } from '../../interfaces/customer';

@Component({
    filters: { typeDoc },
    components: {
        AppointmentDate,
        TimeSelect,
        ThirdStepAppointment,
        AppointmentHeader,
        FillInformation: () =>
            import('@/components/DialogAppointment/FillInformation.vue')
    }
})
export default class RequestAppointment extends Mixins(
    HandleProjectChangeMixin,
    FillInformationMixin
) {
    @Prop({ required: true }) appointment!: Appointment;
    form: { date: string; hour: string } & Pick<
        Partial<Customer>,
        'documentType' | 'documentNumber'
    > = {
        date: this.appointment.fecha,
        hour: this.appointment.hora
    };

    get appointmentCustomers() {
        return appointmentModule.appointment?.customers;
    }

    get customer() {
        return customerModule.selectedCustomer;
    }

    get customerId() {
        return customerModule.selectedCustomer?._id;
    }

    set customerId(id: string | undefined) {
        customerModule.selectCustomer(
            appointmentModule.appointment?.customers?.find(
                ({ _id }) => _id === id
            )
        );
    }

    get model() {
        return appointmentModule.selectedModel || {};
    }

    step = 0;
    error = '';

    async saveOrContinue() {
        if (
            this.customer &&
            (!this.customer.documentType || !this.customer.documentNumber)
        ) {
            await this.saveCustomer(this.customer, this.form);
        }
        this.step = 2;
    }

    async store() {
        try {
            if (this.customer?._id) {
                await appointmentModule.requestAppointment({
                    clienteId: this.customer._id,
                    esClientePrincipal: this.customer.type === 'principal',
                    fecha: this.form.date,
                    hora: this.form.hour,
                    idCita: this.appointment._id.CitaModel__id
                });
                await appointmentModule.index();
                this.next();
            }
        } catch ({ mensaje }) {
            this.error =
                typeof mensaje === 'string'
                    ? mensaje
                    : 'Lo sentimos, pero no podemos procesar tu solicitud. Inténtalo nuevamente.';
        }
    }

    next() {
        this.step++;
    }

    close() {
        this.$emit('close');
    }
}
