var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"f-13 border-bottom border-primary pb-2 pl-1 mb-2 d-flex justify-content-between"},[_c('div',{staticClass:"col-7 col-lg-4 px-0 d-flex align-items-end"},[_c('p',{staticClass:"m-0"},[_vm._v("Registros de Búsqueda: "+_vm._s(_vm.tableData.total))])]),(!_vm.hiddenLegend)?[_c('legend-dialog',{staticClass:"d-none d-lg-flex"}),_c('el-button',{staticClass:"d-lg-none col-5 col-sm-3 col-md-2",attrs:{"type":_vm.isUser ? 'primary' : 'success',"size":"mini"},on:{"click":function($event){return _vm.openDialog({ index: 3 })}}},[_vm._v(" Ver Leyenda ")])]:_vm._e()],2),_c('table',{staticClass:"w-100 f-13 table-appoinment text-center"},[_c('thead',[_c('tr',{class:_vm.headerRowClassName},[(
                        _vm.state == 'solicitud-de-visitas' ||
                        _vm.state == 'favoritos'
                    )?[_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}})]:_vm._e(),_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v("DI")]),_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" Proyecto ")]),(!_vm.isUser)?_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" Agente ")]):_vm._e(),_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" Prospecto(s) ")]),_c('th',{staticClass:"p-2 d-lg-table-cell d-none",staticStyle:{"width":"110px"},attrs:{"scope":"col"}},[_vm._v(" Modelo y Precio desde ")]),_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" Condiciones ")]),(_vm.state !== 'favoritos')?[_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" F. Cita ")]),(_vm.state !== 'solicitud-de-visitas')?_c('th',{staticClass:"p-2 d-lg-table-cell d-none",attrs:{"scope":"col"}},[_vm._v(" Estado ")]):_vm._e()]:_vm._e(),(
                        _vm.state !== 'validadas' &&
                        _vm.state !== 'invalidadas' &&
                        _vm.isUser
                    )?_c('th',{staticClass:"pr-2 d-lg-table-cell d-none",staticStyle:{"max-width":"90px"},attrs:{"scope":"col","colspan":"2"}},[_vm._v(" Acciones ")]):_vm._e()],2)]),_c('tbody',[_vm._l((_vm.tableData.data),function(row,index){return [_c('tr',{key:index + 'table-row' + _vm._uid,staticClass:"d-block d-lg-none bg-info-2 text-white p-2 text-left"},[_c('td',{attrs:{"colspan":"10"}},[_vm._v("Proyecto "+_vm._s(row.name))])]),_c('tr',{key:index + 'table-row',class:{
                        expirada: row.estado === 6,
                        cancelada: [3, 5, 7, 8].includes(row.estado),
                        confirmada: [2, 4].includes(row.estado),
                        agendada:
                            row.estado === 1 &&
                            ![1, 2].includes(row.preState),
                        reagendada: [1, 2].includes(row.preState),
                        default: !row.estado
                    }},[(
                            _vm.state == 'solicitud-de-visitas' ||
                            _vm.state == 'favoritos'
                        )?_c('td',{staticClass:"py-2 d-lg-table-cell d-none font-weight-normal",attrs:{"scope":"row"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('el-image',{staticClass:"img-w",attrs:{"src":row.image_medium,"fit":"cover"}})],1)]):_vm._e(),_c('th',{staticClass:"py-2 d-lg-table-cell d-none font-weight-normal position-relative cell-contact",attrs:{"scope":"row"}},[_c('p',{staticClass:"m-0 pb-2"},[_vm._v(" "+_vm._s(_vm._f("readMore")(row.tradename))+" ")]),([1, 2, 4].includes(row.estado))?_c('el-button',{staticClass:"f-11 px-1 w-100 text-warning btn-contact bg-white position-absolute",attrs:{"type":"warning","size":"mini","plain":""},on:{"click":function($event){return _vm.openContactDialog(row)}}},[_vm._v(" Ver Contacto ")]):_vm._e()],1),_c('td',{staticClass:"p-2 d-lg-table-cell d-none position-relative"},[_c('p',{staticClass:"m-0 pb-2"},[_vm._v(_vm._s(row.name))]),(
                                _vm.state !== 'solicitud-de-visitas' &&
                                _vm.state !== 'favoritos'
                            )?_c('router-link',{staticClass:"link-project f-11 px-1 text-info border-info text-decoration-none text-reset el-button el-button--default el-button--mini",attrs:{"to":{
                                name: 'Project',
                                params: { id: row.project_id }
                            },"target":"_blank"}},[_vm._v(" Ir a Proyecto ")]):_vm._e()],1),(!_vm.isUser && row.agent)?_c('th',{staticClass:"py-2 d-lg-table-cell d-none font-weight-normal",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(row.agent.nombres)+" "+_vm._s(row.agent.apellidoPaterno)+" "+_vm._s(row.agent.apellidoMaterno)+" ")]):_vm._e(),_c('td',{staticClass:"p-2"},[_c('div',{staticClass:"text-left d-none d-lg-block"},_vm._l((row.customers),function(customer){return _c('p',{key:customer._id,staticClass:"m-0 text-truncate"},[_vm._v(" - "+_vm._s(customer.firstSurname)+" "+_vm._s(customer.secondSurname)+" "+_vm._s(customer.name)+" ")])}),0),_c('div',{staticClass:"d-flex text-left align-items-center d-lg-none"},[_c('ul',{staticClass:"detailItem list-unstyled"},[_c('li',[_c('label',{staticClass:"detailItem__label"},[_vm._v(" Prospectos: ")]),_c('p',{staticClass:"detailItem__description"},_vm._l((row.customers),function(customer){return _c('span',{key:customer._id + 'span',staticClass:"d-block"},[_vm._v(" - "+_vm._s(customer.name)+" "+_vm._s(customer.firstSurname)+" "+_vm._s(customer.secondSurname)+" ")])}),0)]),_c('li',[_c('label',{staticClass:"detailItem__label"},[_vm._v(" Modelo: ")]),_c('span',{staticClass:"detailItem__description"},[_c('b',[_vm._v(_vm._s(row.nameTipologia))])])]),_c('li',[_c('label',{staticClass:"detailItem__label"},[_vm._v(" Precio desde: ")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(" S/. "+_vm._s(_vm._f("priceProject")(row.minPrice))+" ")])]),_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("Comisión:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.comisionTipologia || row.minCurrentComision)+"% ")])]),_c('el-collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.isRowExpanded(
                                                index + 'table-row'
                                            )
                                        ),expression:"\n                                            isRowExpanded(\n                                                index + 'table-row'\n                                            )\n                                        "}]},[_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("Cond.de Pago:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.condition_pago)+"% ")])]),(row.fechaUTF)?_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("F. Cita:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.fechaUTF)+" ")])]):_vm._e(),(row.hora)?_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("H. Cita:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.hora)+" ")])]):_vm._e(),(row.estadoCita)?_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("Estado:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.estadoCita)+" ")])]):_vm._e(),_c('li',[_c('label',{staticClass:"detailItem__label",attrs:{"for":""}},[_vm._v("DI:")]),_c('span',{staticClass:"detailItem__description"},[_vm._v(_vm._s(row.tradename)+" ")])]),_c('li',[(
                                                    _vm.isUser &&
                                                    ![5, 7].includes(
                                                        row.estado
                                                    )
                                                )?_c('action',{staticClass:"p-2",staticStyle:{"width":"100%"},attrs:{"tag":"div","row":row},on:{"select":_vm.openDialog,"open-request-appointment":_vm.openRequestAppointment,"show-contact":_vm.openContactDialog}}):_vm._e()],1)])])],1),_c('div',{staticClass:"px-0 text-center"},[_c('el-button',{staticClass:"border-0 f-18 text-primary bg-transparent",attrs:{"icon":_vm.isRowExpanded(index + 'table-row')
                                            ? 'el-icon-minus'
                                            : 'el-icon-plus',"circle":""},on:{"click":function($event){return _vm.toggleRowExpansion(
                                            index + 'table-row'
                                        )}}})],1)])]),_c('td',{staticClass:"p-2 d-lg-table-cell d-none",staticStyle:{"max-width":"100px"}},[_c('b',[_vm._v(_vm._s(row.nameTipologia))]),_c('br'),_vm._v(" S/. "+_vm._s(_vm._f("priceProject")(row.minPrice))+" ")]),_c('td',{staticClass:"p-2 d-lg-table-cell d-none"},[_c('table',{staticClass:"mx-auto text-left l-h-15 f-12"},[(
                                    row.comisionTipologia ||
                                    row.minCurrentComision ===
                                        row.maxCurrentComision
                                )?[_c('tr',{staticClass:"bg-transparent"},[_vm._m(0,true),_c('td',{staticClass:"text-nowrap border-0 pl-1"},[_vm._v(" "+_vm._s(row.comisionTipologia || row.minCurrentComision)+"% ")])])]:[_c('tr',{staticClass:"bg-transparent"},[_vm._m(1,true),_c('td',{staticClass:"text-nowrap border-0"},[_vm._v(" "+_vm._s(row.minCurrentComision)+"% ")])]),_c('tr',{staticClass:"bg-transparent"},[_vm._m(2,true),_c('td',{staticClass:"text-nowrap border-0"},[_vm._v(" "+_vm._s(row.maxCurrentComision)+"% ")])])],_c('tr',{staticClass:"bg-transparent"},[_vm._m(3,true),_c('td',{staticClass:"text-nowrap border-0"},[_vm._v(" "+_vm._s(row.condition_pago)+"% ")])])],2)]),(_vm.state !== 'favoritos')?[_c('td',{staticClass:"p-2 d-lg-table-cell d-none text-nowrap"},[_c('p',{staticClass:"m-0 text-center"},[_vm._v(" "+_vm._s(row.fechaUTF)+" ")]),_c('p',{staticClass:"m-0 text-center"},[_vm._v(_vm._s(row.hora))])]),(_vm.state !== 'solicitud-de-visitas')?_c('td',{staticClass:"p-2 d-lg-table-cell d-none",staticStyle:{"max-width":"170px"}},[_vm._v(" "+_vm._s(row.estadoCita)+" ")]):_vm._e()]:_vm._e(),(_vm.isUser && ![4, 5, 7].includes(row.estado))?_c('action',{staticClass:"p-2 d-lg-table-cell d-none",staticStyle:{"width":"80px"},attrs:{"tag":"td","row":row},on:{"select":_vm.openDialog,"open-request-appointment":_vm.openRequestAppointment,"show-contact":_vm.openContactDialog}}):_vm._e()],2)]}),(_vm.tableData.data.length < 1)?[_c('tr',{staticClass:"border border-secondary"},[_c('td',{staticClass:"py-2 f-14",attrs:{"colspan":"10"}},[(_vm.state === 'favoritos')?[_vm._v("No hay Proyectos en favoritos")]:[_vm._v("No hay citas")]],2)])]:_vm._e()],2)]),_vm._l((_vm.dialogues),function(dialog,index){return _c('el-dialog',{key:index + 'dialog',attrs:{"title":dialog.title,"custom-class":dialog.customClass || 'dialog-appoinment-table',"destroy-on-close":dialog.destroyOnClose !== undefined
                ? dialog.destroyOnClose
                : false,"show-close":dialog.showClose !== undefined ? dialog.showClose : true,"visible":dialog.visible,"width":dialog.width || '90%'},on:{"update:visible":function($event){return _vm.$set(dialog, "visible", $event)}}},[(dialog.visible)?_c(dialog.component,{tag:"component",staticClass:"w-100",attrs:{"appointment":dialog.row},on:{"close":function($event){dialog.visible = false}}},[(
                    _vm.isUser &&
                    ![4, 5, 7].includes(
                        dialog.row ? dialog.row.estado : null
                    )
                )?_c('action',{staticClass:"col-12 col-sm-3 d-flex flex-wrap align-self-center flex-column flex-sm-row px-0 pt-2 pt-sm-0",attrs:{"slot":"action","row":dialog.row},on:{"select":_vm.openDialog},slot:"action"}):_vm._e()],1):_vm._e()],1)}),_c('div',{staticClass:"d-flex justify-content-center pt-3"},[_c('el-pagination',{attrs:{"page-size":5,"current-page":_vm.tableData.page,"total":_vm.tableData.total,"layout":"prev, pager, next"},on:{"current-change":_vm.changePage}})],1),_c('el-dialog',{attrs:{"custom-class":"custom-dialog","show-close":false,"visible":_vm.showContact,"width":"500px"},on:{"update:visible":function($event){_vm.showContact=$event}}},[(_vm.showContact)?_c('show-contact',{staticClass:"w-100",attrs:{"appointment":_vm.selectedAppointment},on:{"close":function($event){_vm.showContact = false}}}):_vm._e()],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"font-weight-bold border-0"},[_c('strong',[_vm._v("Comisión:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"font-weight-bold border-0"},[_c('strong',[_vm._v("Com. min:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"font-weight-bold border-0 text-dark"},[_c('strong',[_vm._v("Com. max:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"font-weight-bold border-0"},[_c('strong',[_vm._v("Condición de Pago:")])])}]

export { render, staticRenderFns }