

















































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import HandleProjectChangeMixin from '@/mixins/handle-project-change';
import { Appointment } from '@/interfaces/appoinment';
import AppointmentHeader from './AppointmentHeader.vue';
import ThirdStepAppointment from './ThirdStepAppointment.vue';
import SecondStepAppointment from './SecondStepAppointment.vue';
import { appointmentModule } from '@/store/modules/appointment';
import { customerModule } from '@/store/modules/customer';
import { priceProject } from '@/filters/price-project';

@Component({
    filters: { priceProject },
    components: {
        SecondStepAppointment,
        ThirdStepAppointment,
        AppointmentHeader
    }
})
export default class ReRequestAppointment extends Mixins(
    HandleProjectChangeMixin
) {
    @Prop({ required: true }) appointment!: Appointment;
    form = { date: '', hour: '' };

    get selectedAppointment() {
        return appointmentModule.appointment;
    }

    get customer() {
        return customerModule.selectedCustomer;
    }

    get model() {
        return appointmentModule.selectedModel || {};
    }

    step = 0;
    error = '';

    async created() {
        await customerModule.selectCustomer(this.appointment.customers[0]);
        await appointmentModule.getAppointment({
            CitaModel__id: this.appointment._id.CitaModel__id,
            enableLogging: 'true'
        });
    }

    async store() {
        try {
            await appointmentModule.reagendar({
                fecha: this.form.date,
                hora: this.form.hour,
                idCita: this.appointment._id.CitaModel__id
            });
            await appointmentModule.index();

            this.next();
        } catch ({ mensaje }) {
            this.error =
                typeof mensaje === 'string'
                    ? mensaje
                    : 'Lo sentimos, pero no podemos procesar tu solicitud. Inténtalo nuevamente.';
            this.step = 400;
        }
    }

    next() {
        this.step++;
    }

    close() {
        this.$emit('close');
    }
}
