


















































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Appointment } from '@/interfaces/appoinment';
import { agentModule } from '@/store/modules/agent';

@Component
export default class Action extends Vue {
    @Prop({ default: 'div' }) tag!: string;
    @Prop({ required: true }) row!: Appointment;

    @Emit() select(index = 0, row: Appointment, options: object) {
        return { index, row, options };
    }

    get disaffiliated() {
        return agentModule.disaffiliated;
    }

    showComment(comment: string) {
        this.$alert(comment, 'Comentario de la Cita', {
            type: 'info',
            center: true
        });
    }

    @Emit() 
    showContact() {
        return this.row;
    }
    
    @Emit()
    openRequestAppointment() {
        return this.row._id.CitaModel__id;
    }
}
