








































































































import { Appointment } from '@/interfaces/appoinment';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ShowContact extends Vue {
    @Prop({ required: true }) appointment!: Appointment;

    close() {
        this.$emit('close');
    }
}
