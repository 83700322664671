












































































































import { Component, Mixins } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import FilterTable from '@/components/AppoinmentTable/Filter.vue';
import AppoinmentTable from '@/components/AppoinmentTable/Table.vue';
import FilterTableMixin from '@/mixins/filter-table';
import { FilterAppointment } from '@/interfaces/filter';

interface ActionButton {
    label: string;
    active: boolean;
    state: string;
    count: number;
    visibleIn: string[];
}

@Component({
    components: { FilterTable, AppoinmentTable }
})
export default class Appointment extends Mixins(FilterTableMixin) {
    get buttons(): ActionButton[] {
        return [
            {
                label: 'Mis citas solicitadas al DI',
                active: true,
                state: 'agendadas',
                count: 0,
                visibleIn: [
                    'agendadas',
                    'confirmadas',
                    'canceladas',
                    'expiradas'
                ]
            },
            {
                label: 'Mis citas confirmadas por DI',
                active: false,
                state: 'confirmadas',
                count: 0,
                visibleIn: [
                    'agendadas',
                    'confirmadas',
                    'canceladas',
                    'expiradas'
                ]
            },
            {
                label: 'Mis citas canceladas por DI',
                active: false,
                state: 'canceladas',
                count: 0,
                visibleIn: [
                    'agendadas',
                    'confirmadas',
                    'canceladas',
                    'expiradas'
                ]
            },
            {
                label: 'Mis citas expiradas',
                active: false,
                state: 'expiradas',
                count: 0,
                visibleIn: [
                    'agendadas',
                    'confirmadas',
                    'canceladas',
                    'expiradas'
                ]
            },
            {
                label: 'Mis visitas Validadas por DI',
                active: false,
                state: 'validadas',
                count: 0,
                visibleIn: ['validadas', 'invalidadas']
            },
            {
                label: 'Mis visitas Invalidadas por DI',
                active: false,
                state: 'invalidadas',
                count: 0,
                visibleIn: ['validadas', 'invalidadas']
            }
        ].reduce((buttons, button) => {
            button.active = this.$route.params.state === button.state;
            button.count = appointmentModule.counters
                ? (appointmentModule.counters as any)[button.state]
                : 0;
            if (button.visibleIn.includes(this.$route.params.state)) {
                buttons.push(button);
            }
            return buttons;
        }, [] as ActionButton[]);
    }

    get tableData() {
        return appointmentModule.response;
    }

    get blocked() {
        return this.isUser ? ['customer', 'user'] : ['cliente'];
    }

    get title() {
        this.hiddenLegend = false;
        if (!this.isUser && this.$route.params.state !== 'favoritos') {
            return 'Seguimiento de citas de mis agentes';
        } else if (!this.isUser && this.$route.params.state === 'favoritos') {
            this.hiddenLegend = true;
            return 'Seguimiento de citas de mis agentes';
        } else if (this.$route.params.state === 'agendadas') {
            return 'Estado de citas con el DI';
        } else if (this.$route.params.state === 'confirmadas') {
            return 'Mis citas confirmadas por el Desarrollador Inmobiliario';
        } else if (this.$route.params.state === 'canceladas') {
            return 'Mis citas canceladas por el Desarrollador Inmobiliario';
        } else if (this.$route.params.state === 'expiradas') {
            return 'Estado de citas con el DI';
        } else if (this.$route.params.state === 'validadas') {
            return 'Estado de visitas con el DI';
        } else if (this.$route.params.state === 'invalidadas') {
            return 'Mis visitas Invalidadas por Desarrollador Inmobiliario';
        } else if (this.$route.params.state === 'favoritos') {
            this.hiddenLegend = true;
            return 'Proyectos favoritos de mis Prospectos';
        }
        return '';
    }

    hiddenLegend = false;

    filterData(data: FilterAppointment) {
        appointmentModule.setFilter(data);
        appointmentModule.index();
    }

    $refs!: {
        carouseltitle: any;
    };
    
    mounted() {
        if(this.$route.params.state === 'solicitud-de-visitas') {
            this.$router.push({ name: 'Customer'});
        }

        this.$nextTick(() => {
            this.$refs.carouseltitle.setActiveItem(this.$route.params.state);
        });
    }
}
