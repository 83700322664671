































































import { Component, Mixins } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import IsUser from '@/mixins/is-user';

@Component
export default class Legend extends Mixins(IsUser) {
    get state() {
        return appointmentModule.filter.state;
    }
}
