var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component"},[([1, 2, 4].includes(_vm.row.estado))?_c('el-button',{staticClass:"f-11 px-1 w-100 mb-1 text-warning bg-white d-lg-none",attrs:{"type":"warning","size":"mini","plain":""},on:{"click":_vm.showContact}},[_vm._v(" Ver Contacto ")]):_vm._e(),(_vm.row.estado === 0 || !_vm.row.estado)?_c('router-link',{staticClass:"el-button white-space-normal f-11 px-1 mb-1 ml-0 w-100 el-button--info el-button--mini text-decoration-none",attrs:{"to":{ name: 'Project', params: { id: _vm.row.project_id } },"target":"_blank"}},[_vm._v("Ver Proyecto ")]):_vm._e(),(_vm.row.estado === 0 && !_vm.disaffiliated)?_c('el-button',{staticClass:"white-space-normal f-11 px-1 mb-1 ml-0 w-100",attrs:{"type":"warning","size":"mini"},on:{"click":function($event){return _vm.openRequestAppointment()}}},[_vm._v(" Solicitar Cita ")]):_vm._e(),(_vm.row.estado === 8 && !_vm.disaffiliated)?_c('el-button',{staticClass:"white-space-normal f-11 px-1 mb-1 ml-0 w-100",attrs:{"size":"mini"},on:{"click":function($event){return _vm.select(7, _vm.row)}}},[_vm._v(" Volver a solicitar cita ")]):_vm._e(),(
            [1, 2].includes(_vm.row.estado) &&
            ![1, 2].includes(_vm.row.preState) &&
            !_vm.disaffiliated
        )?_c('el-button',{staticClass:"white-space-normal f-11 px-1 mb-1 ml-0 w-100",attrs:{"size":"mini"},on:{"click":function($event){return _vm.select(5, _vm.row)}}},[_vm._v(" Reagendar Cita ")]):_vm._e(),(
            (![4, 5, 7].includes(_vm.row.estado) && _vm.row.comentario) ||
            ([4, 5, 7].includes(_vm.row.estado) && _vm.row.comentarioVisit)
        )?_c('el-button',{staticClass:"white-space-normal f-11 px-1 mb-1 ml-0 w-100 bg-brown text-white",attrs:{"size":"mini"},on:{"click":function($event){_vm.showComment(
                [4, 5, 7].includes(_vm.row.estado)
                    ? _vm.row.comentarioVisit
                    : _vm.row.comentario
            )}}},[_vm._v(" Ver Comentario ")]):_vm._e(),([1, 2, 3, 6].includes(_vm.row.estado))?_c('el-button',{staticClass:"white-space-normal f-11 px-1 w-100 ml-0",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.select(4, _vm.row)}}},[_vm._v(" Eliminar Cita ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }