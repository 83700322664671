var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appointment)?_c('table',{staticClass:"text-left"},[(_vm.appointment.name)?_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("Modelo:")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[(_vm.appointment.tipoProyecto === 'Departamento')?[_vm._v(" "+_vm._s(_vm.appointment.nombreTipologia[0])+" "+_vm._s(_vm.appointment.name)+" ")]:[_vm._v(_vm._s(_vm.appointment.name))]],2)]):_vm._e(),(
            _vm.appointment.tipoProyecto !== 'Oficina' &&
            _vm.appointment.tipoProyecto !== 'Lote'
        )?[_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v(" Dormitorios: ")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[(
                        _vm.appointment.minDormitorios ===
                        _vm.appointment.maxDormitorios
                    )?[_vm._v(_vm._s(_vm.appointment.minDormitorios))]:[_vm._v(_vm._s(_vm.appointment.minDormitorios)+" - "+_vm._s(_vm.appointment.maxDormitorios))]],2)]),_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("Baños:")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[(_vm.appointment.minBanios === _vm.appointment.maxBanios)?[_vm._v(_vm._s(_vm.appointment.minBanios))]:[_vm._v(_vm._s(_vm.appointment.minBanios)+" - "+_vm._s(_vm.appointment.maxBanios))]],2)])]:_vm._e(),(_vm.appointment.tipoProyecto !== 'Lote')?_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("A. Const(m2):")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[(
                    _vm.appointment.minMettersBuilt ===
                    _vm.appointment.maxMettersBuild
                )?[_vm._v(_vm._s(_vm.appointment.minMettersBuilt))]:[_vm._v(_vm._s(_vm.appointment.minMettersBuilt)+" - "+_vm._s(_vm.appointment.maxMettersBuild))]],2)]):_vm._e(),_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("A. Total(m2):")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[_vm._v(" "+_vm._s(_vm.appointment.minMetters)+" ")])]),(_vm.appointment.tipoProyecto === 'Departamento')?[(_vm.appointment.floors)?_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("Pisos:")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[_vm._v(" "+_vm._s(_vm.appointment.floors .filter( function (item, pos, self) { return self.indexOf(item) === pos; } ) .join(', '))+" ")])]):_vm._e(),(_vm.appointment.orientation)?_c('tr',[_c('th',{staticClass:"f-12 font-weight-bold py-0 text-dark"},[_vm._v("Vista:")]),_c('th',{staticClass:"f-12 font-weight-normal py-0 pl-1"},[_vm._v(" "+_vm._s(_vm.appointment.orientation)+" ")])]):_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }