import { Vue, Component } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';

@Component
export default class HandleProjectChangeMixin extends Vue {
    get model() {
        return appointmentModule.selectedModel || {};
    }
    
    get selectedAppointment() {
        return appointmentModule.appointment;
    }

    mounted() {
        this.$socket.on('project_change', this.handleProjectChange);
        this.$socket.on('typology_change', this.handleTypologyChange);
    }

    handleProjectChange(payload: { _id: string; condition_pago: number }) {
        if (payload?._id === this.model.project?._id) {
            appointmentModule.updatePaymentCondition(payload.condition_pago);
            if (this.selectedAppointment?.actualConditions) {
                appointmentModule.setAppointment({
                    ...this.selectedAppointment,
                    actualConditions: {
                        ...this.selectedAppointment.actualConditions,
                        project: {
                            condition_pago: payload.condition_pago
                        }
                    }
                });
            }
        }
    }

    handleTypologyChange(payload: {
        _id: string;
        comisionTipologia: number;
        min_price?: number;
    }) {
        if (payload?._id === this.model._id) {
            appointmentModule.updateCommission(payload.comisionTipologia);
            if (this.selectedAppointment?.actualConditions) {
                const typology: {
                    min_price?: number;
                    comisionTipologia: number;
                } = {
                    ...this.selectedAppointment.actualConditions.typology,
                    comisionTipologia: payload.comisionTipologia
                };
                if (payload.min_price) {
                    typology.min_price = payload.min_price;
                }
                appointmentModule.setAppointment({
                    ...this.selectedAppointment,
                    actualConditions: {
                        ...this.selectedAppointment.actualConditions,
                        typology
                    }
                });
            }
        }
    }

    beforeDestroy() {
        this.$socket.off('project_change', this.handleProjectChange);
        this.$socket.off('typology_change', this.handleTypologyChange);
    }
}
