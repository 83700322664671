













































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Appointment } from '@/interfaces/appoinment';
import { banks } from '@/filters/banks';
import { priceProject } from '@/filters/price-project';
import { readMore } from '@/filters/read-more';
import AppoinmentDialog from './Dialog/Appoinment.vue';
import ProjectDialog from './Dialog/Project.vue';
import PropertyDialog from './Dialog/Property.vue';
import DeleteAppointment from '@/components/DeleteAppointment.vue';
import DialogRe from '@/components/DialogRe.vue';
import RequestAppointment from '@/components/DialogAppointment/RequestAppointment.vue';
import ReRequestAppointment from '@/components/DialogAppointment/ReRequestAppointment.vue';
import Action from './Action.vue';
import ShowContact from './Dialog/ShowContact.vue';
import LegendDialog from './Dialog/Legend.vue';
import { appointmentModule } from '@/store/modules/appointment';
import { contactModule } from '@/store/modules/contact';
import ExpandTableMixin from '@/mixins/expand-table';
import IsUser from '@/mixins/is-user';
import { customerModule } from '@/store/modules/customer';

@Component({
    filters: { banks, priceProject, readMore },
    components: {
        ProjectDialog,
        PropertyDialog,
        LegendDialog,
        Action,
        ShowContact
    }
})
export default class AppoinmentTable extends Mixins(ExpandTableMixin, IsUser) {
    @Prop({ required: true }) readonly tableData!: {
        data: Appointment[];
        total: number;
    };
    @Prop({ type: String, default: 'bg-primary text-white' })
    readonly headerRowClassName!: string;
    @Prop({ type: Boolean, default: false }) readonly hiddenLegend!: boolean;
    showContact = false;
    selectedAppointment?: Appointment = undefined;

    get state() {
        return this.$route.params.state;
    }

    dialogues = [
        {
            title: 'Datos de la Cita',
            visible: false,
            component: AppoinmentDialog
        },
        {
            title: 'Datos del Proyecto',
            visible: false,
            component: ProjectDialog
        },
        {
            title: 'Datos del Modelo',
            visible: false,
            component: PropertyDialog
        },
        {
            title: 'Leyenda',
            visible: false,
            component: LegendDialog,
            width: '250px'
        },
        {
            visible: false,
            component: DeleteAppointment,
            width: '496px',
            customClass: 'custom-dialog',
            showClose: false,
            destroyOnClose: true
        },
        {
            visible: false,
            component: DialogRe,
            width: '496px',
            customClass: 'custom-dialog',
            showClose: false,
            destroyOnClose: true
        },
        {
            visible: false,
            component: RequestAppointment,
            width: '496px',
            customClass: 'custom-dialog',
            showClose: false,
            destroyOnClose: true
        },
        {
            visible: false,
            component: ReRequestAppointment,
            width: '496px',
            customClass: 'custom-dialog',
            showClose: false,
            destroyOnClose: true
        }
    ];

    @Watch('tableData') async toggleAll() {
        this.rows = [];
        if (
            this.tableData.data.length < 1 &&
            appointmentModule.filter.typeFilter === 'id'
        ) {
            await appointmentModule.setFilter({
                typeFilter: '',
                filterValue: ''
            });
            await appointmentModule.index();
            this.$alert(
                'Esta cita ya no se encuentra en el listado de citas confirmadas',
                'Lo sentimos.',
                { type: 'info', center: true }
            );
        }
    }

    openDialog({ index, row }: { index: number; row: Appointment }) {
        this.$set(this.dialogues, index, {
            ...this.dialogues[index],
            visible: !this.dialogues[index].visible,
            row
        });
    }

    async openRequestAppointment(id: string) {
        await appointmentModule.getAppointment({
            CitaModel__id: id
        });
        const { appointment } = appointmentModule;
        if (appointment) {
            await customerModule.selectCustomer(appointment.customers[0]);
            this.openDialog({ index: 6, row: appointment });
        }
    }

    async openContactDialog(row: Appointment) {
        const contact = await contactModule.getContactByProjectId(
            row.project_id
        );
        this.selectedAppointment = { ...row, ...contact };
        this.showContact = true;
    }

    changePage(page: number) {
        this.$router.push({
            query: { ...this.$route.query, page: page.toString() }
        });
    }

    showAssociated(row: Appointment) {
        const [, associated] = row.customers;
        this.$alert(
            `${associated?.name} ${associated?.firstSurname} ${associated?.secondSurname}`,
            'Asociado',
            { type: 'info', center: true }
        );
    }
}
