
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
import { Appointment } from '@/interfaces/appoinment';
import AppointmentDate from '@/components/DialogAppointment/AppointmentDate.vue';
import TimeSelect from '@/components/DialogAppointment/TimeSelect.vue';

@Component({
    components: { TimeSelect, AppointmentDate }
})
export default class DialogRe extends Vue {
    @Prop({ required: true }) appointment!: Appointment;
    form = { date: '', hour: '' };
    error = '';
    active = 0;

    next() {
        this.active++;
    }

    async store() {
        try {
            await appointmentModule.reagendar({
                idCita: this.appointment._id.CitaModel__id,
                fecha: this.form.date,
                hora: this.form.hour
            });
            await appointmentModule.index();
            this.next();
        } catch ({ mensaje }) {
            this.error = mensaje;
            this.active = 400;
        }
    }

    close() {
        this.$emit('close', true);
    }
}
